import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`How does Remote Learning differ from Distance Learning?`}</h2>
    <ul>
      <li parentName="ul">{`Remote instructor-led training mirrors all the benefits of classroom instructor-led training with the added bonus of being able to choose almost any location.`}</li>
      <li parentName="ul">{`The Instructor's presentation is interactive with all students. This is possible because of the use of innovative technologies and best practices. Labs are conducted during the class time with the required assistance from the Instructor.`}</li>
    </ul>
    <h2>{`How do I know if I am a good candidate to be a Remote Learning student?`}</h2>
    <p>{`A strong Remote Learning candidate:`}</p>
    <ul>
      <li parentName="ul">{`Understands remote technologies, virtual computerization and Internet technologies`}</li>
      <li parentName="ul">{`Is technically literate and has strong computer skills (2-4 years experience)`}</li>
      <li parentName="ul">{`Has taken a web or distance learning class before`}</li>
      <li parentName="ul">{`Has access to a computer that meets the system requirements`}</li>
    </ul>
    <h2>{`Can Remote Learning students ask questions and get lab assistance?`}</h2>
    <p>{`Yes, the Instructor will be working with the remote student much like they would if they were in the classroom. The Instructor will be able to view and assist remote students with their labs. Additionally there is a "how to" portion of the User guide that every Remote Learning student will be provided prior to class start.`}</p>
    <h2>{`What are the location choices?`}</h2>
    <p>{`Limitless, as long as the student machine has appropriate computer and Internet capabilities. Please see your Account Manager for System Requirements.`}</p>
    <h2>{`What is the price?`}</h2>
    <p>{`The retail price is the list price on our website.`}</p>
    <h2>{`How do I register?`}</h2>
    <p>{`Please call 518-437-1600 and ask for your account manager to register for any remote class or email us at `}<a parentName="p" {...{
        "href": "mailto:vision@compvisions.com"
      }}>{`vision@compvisions.com`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      