import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Divider } from '@material-ui/core';
import ResponsiveHeading from '../../../components/utils/responsive-heading';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <ResponsiveHeading mdxType="ResponsiveHeading">What is Remote Learning?</ResponsiveHeading>
    <Divider mdxType="Divider" />
    <p>{`Computer Visions' Remote Learning is a unique approach leveraging key technologies to provide the full classroom experience to any remote location. Our virtual, instructor-led training is delivered in the standard instructor-led training format of 8:30AM-4:30PM Eastern Time, on consecutive days.`}</p>
    <p>{`Remote students will be able to hear the Instructor's lecture and see the Instructor's slide presentation and demonstrations, as well as perform labs by remotely controlling the in-classroom student computer or perform the work on your own computer if you have all the necessary software. Students are also able to communicate with the Instructor via microphones in the classroom. Remote Learning offers students the benefits of our local public schedule but enjoyed anywhere in the world. Private/custom classes are also available.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      